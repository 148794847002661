<template>
   <div :class="`text-${props.align}`" class="my-6">
      <p class="text-sm font-medium">
         Talkime is
         <span class="text-primary-500"
            >HIPPA Verified and ISO 27001 Certified</span
         >. We are committed to provide you with the highest standards of
         security and privacy.
      </p>
      <div
         :class="props.align === 'center' ? 'mx-auto justify-center' : ''"
         class="my-4 flex"
      >
         <div class="mr-2">
            <img src="@/assets/image/hippa.png" />
         </div>
         <div>
            <img src="@/assets/image/iso.png" />
         </div>
      </div>
   </div>
</template>
<script setup>
   const props = defineProps({
      align: {
         type: String,
         default: 'center',
      },
   });
</script>
